.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #460e85;
}

.text-center h1 {
  margin-bottom: 20px;
  color: white;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000099;
  text-align: center;
}

.modal-main {
  position: fixed;
  background: white;
  width: 35rem;
  height: auto;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}

.modal-head {
  align-self: start;
  padding-top: 10px;
  padding-left: 10px;
}

.modal-body {
  display: flex;
  flex: 1;
  padding: 10px 10px 0 10px;
}

.modal-error {
  display: flex;
  flex: 1;
  padding: 0 0 0 10px;
}

.warning {
  color: rgb(221, 119, 17);
}

.error {
  color: red;
}

.text-field {
  width: 100%;
  font-family: Helvetica, Arial, sans-serif;
  padding: 8px 16px;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  color: #1a1f36;
  outline: 0;
  border: 1px solid #ccc;
  transition: all .3s ease;
  background-color: #fff;
}

.modal.display-block {
  display: block;
}

.modal.display-none {
  display: none;
}

.btn-container {
  padding-top: 10px;
  margin-bottom: 10px;
}

.btn {
  margin-right: 10px;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
